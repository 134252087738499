import Vue from 'vue'
import VueRouter  from 'vue-router'
import SubmitForm from '../components/SubmitForm.vue';

Vue.use(VueRouter);

const routes = [
    { path: '/', component: SubmitForm },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;