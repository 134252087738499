<template>
    <el-container class="container">
        <el-tabs v-model="activeTab" class="tabs"  type="border-card">
            <el-tab-pane label="提交订单" name="submit">
                <div class="form">
                    <form>
                        <div class="form-group">
                            <label>预设信息:</label>
                            <el-autocomplete type="text" style="width: 100%;padding-top: 5px"
                                             v-model="selectedStaffName"
                                             :fetch-suggestions="querySearch"
                                             placeholder="点击选择预设信息,若无您的基本信息,可手动填入"
                                             @select="handleSelect"
                                             clearable
                                             />
                        </div>
                        <div class="form-group">
                            <label>姓名:</label>
                            <el-input type="text" id="name" v-model="formData.name"/>
                        </div>
                        <div class="form-group">
                            <label>手机:</label>
                            <el-input type="tel" id="phone" v-model="formData.phone"/>
                        </div>
                        <div class="form-group">
                            <label>邮箱:</label>
                            <el-input type="email" id="email" v-model="formData.email"/>
                        </div>
                        <div class="form-group">
                            <label>金额:</label>
                            <el-input ref="amountInput" type="text" id="amount" v-model="formData.amount" />
                        </div>
                        <div class="form-group">
                            <label>选择付款方式:</label>
                            <div class="payment-methods">
                                <el-radio v-model="formData.paymentMethod" label="wxpay" @input="changePayType()"><span class="payment-method-label">微信</span>
                                </el-radio>
                                <el-radio v-model="formData.paymentMethod" label="alipay" @input="changePayType()">
                                    <span class="payment-method-label">支付宝</span></el-radio>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>备注:</label>
                            <el-input type="textarea" id="remark" v-model="formData.remark" class="el-input"></el-input>
                        </div>
                        <el-button type="primary" @click="createOrderFunc" class="submit-btn">提交</el-button>
                        <el-button type="primary" @click="clearForm" class="submit-btn">清空</el-button>
                    </form>
                    <el-divider v-if="showQRCode"></el-divider>
                    <div v-if="showQRCode" style="margin-top: 5px">
                        <div class="noDiv">
                            <label style="cursor: text">订单号: {{orderNo}}</label>
                            <i class="el-icon-copy-document copy-icon" @click="copyNo('orderNo')"></i>
                        </div>
                        <div class="noDiv" style="padding-top: 10px">
                            <label style="cursor: text">支付订单号: {{orderPayNo}}</label>
                            <i class="el-icon-copy-document copy-icon"  @click="copyNo('orderPayNo')"></i>
                        </div>
                        <div v-show="isWxpay" style="margin-top: 10px">
                            <div id="qrcode" ref="qrcode" style="display: inline-block;"></div>
                        </div>
                        <div v-show="!isWxpay" style="margin-top: 10px">
                            <div id="alipayQrcode" ref="alipayQrcode">
                                <iframe :src="alipayHtml"
                                        frameborder="no"
                                        border="0"
                                        marginwidth="0"
                                        marginheight="0"
                                        scrolling="no"
                                        width="155"
                                        height="155"
                                        style="overflow:hidden;">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="查询订单" name="search">
                <div class="form">
                    <label>请输入订单号:</label>
                    <el-input type="text"
                              @clear="paidStatus = null"
                              @keyup.enter="searchOrderFunc()"
                              v-model="searchNo"
                              style="margin-bottom: 10px"
                              clearable/>
                    <el-button type="primary" @click="searchOrderFunc()" class="submit-btn">查询</el-button>
                    <el-divider v-if="paidStatus !== null"></el-divider>
                    <div v-if="paidStatus !== null">
                        <label>订单状态:{{paidStatus !== null && paidStatus ? '支付成功':'待支付'}}</label>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-container>
</template>

<script>
    import {createOrder,searchOrder} from '@/api/api'
    import QRCode from 'qrcodejs2'

    export default {
        data() {
            return {
                activeTab: 'submit',
                formData: {
                    name: '',
                    phone: '',
                    email:'',
                    amount: '',
                    paymentMethod: 'wxpay',
                    remark: ''
                },
                isWxpay: true,
                alipayHtml: null,
                payLoading: false,
                showQRCode: false,
                orderNo: '',
                orderPayNo: '',
                searchNo:'',
                paidStatus: null,
                restaurants: [],
                selectedStaffName:'',
                staffInfoList: [
                    {"name": "吴婷婷", "phone": "17610899961", "email": "wutingting@metasota.ai"},
                    {"name": "张强", "phone": "13146411555", "email": "zhangqiang@metasota.ai"},
                    {"name": "贝宇晴", "phone": "15947926672", "email": "beiyuqing@metasota.ai"},
                    {"name": "张晓云", "phone": "13260026309", "email": "zhangxiaoyun@metasota.ai"},
                    {"name": "孙雯", "phone": "17531870238", "email": "sunwen@metasota.ai"}
                ],
            };
        },
        mounted() {
            this.initRestaurants();
        },
        methods: {
            initRestaurants(){
                for (const staffInfo of this.staffInfoList) {
                    this.restaurants.push({'value': staffInfo.name, 'key': staffInfo})
                }
            },
            clearForm() {
                this.formData.name = '';
                this.formData.phone = '';
                this.formData.email = '';
                this.formData.amount = '';
                this.formData.paymentMethod = 'wxpay';
                this.formData.remark = '';
                this.alipayHtml = null;
                this.orderNo='';
                this.orderPayNo='';
                this.showQRCode = false;
                this.showQRCode = false;
            },
            changePayType(){
                this.isWxpay =  this.formData.paymentMethod=='wxpay';
                this.showQRCode = false;
            },
            createOrderFunc() {
                let _this = this;
                const amountRegex = /^\d+(\.\d{1,2})?$/;
                if (_this.formData.name ==='' || _this.formData.phone ==='' || _this.formData.email ===''
                    || _this.formData.amount ===''|| _this.formData.remark ==='') {
                    _this.$message({
                        type: 'warning',
                        message: '除预设信息外的所有信息都不能为空,请确认后提交订单'
                    })
                    return;
                }
                if (!amountRegex.test(_this.formData.amount)) {
                    _this.$message({
                        type: 'error',
                        message: '金额格式不正确，请输入数字且最多包含两位小数！'
                    })
                    _this.$refs.amountInput.focus();
                    return;
                }

                _this.showQRCode = true;
                _this.payLoading = true;
                _this.$nextTick(() => {
                    if (_this.$refs.qrcode) {
                        _this.$refs.qrcode.innerHTML = '';
                    }
                });
                _this.alipayHtml = null;
                _this.orderNo='';
                _this.orderPayNo='';

                let param = {
                    applicant_name:_this.formData.name,
                    applicant_phone:_this.formData.phone,
                    applicant_email:_this.formData.email,
                    payable:_this.formData.amount*100,
                    pay_type:_this.formData.paymentMethod,
                    remark:_this.formData.remark ,
                }
                createOrder(param).then(res => {
                    if (res.data.code === 0) {
                        _this.orderNo = res.data.data.order_no;
                        _this.orderPayNo = res.data.data.order_pay_no;
                        _this.qrCodeImageUrl = res.data.data.pay_url;
                        if (this.formData.paymentMethod == 'alipay') {
                            _this.alipayHtml = res.data.data.pay_url ;
                        }else{
                            _this.alipayHtml = null;
                           new QRCode('qrcode', {
                                width: 155, // 设置宽度，单位像素
                                height: 155, // 设置高度，单位像素
                                text: res.data.data.pay_url // 设置二维码内容或跳转地址
                            })
                        }
                    } else {
                        _this.$message({
                            type: 'error',
                            message: res.data.msg
                        })
                    }
                }).catch(() => {
                    _this.$message({
                        type: 'error',
                        message: '二维码失败, 请刷新后重试'
                    })
                }).finally(() => {
                    _this.payLoading = false;
                })
            },
            searchOrderFunc(){
                let _this = this;
                if (_this.searchNo ===''){
                    _this.$message({
                        type: 'warning',
                        message: '查询订单号不能为空,填入订单号后再进行查询'
                    })
                    return;
                }
                searchOrder(_this.searchNo).then(res => {
                    if (res.data.code === 0) {
                        _this.paidStatus = res.data.data.paid;
                    } else {
                        _this.$message({
                            type: 'error',
                            message: res.data.msg
                        })
                    }
                }).catch(() => {
                    _this.$message({
                        type: 'error',
                        message: '查询失败,请稍后再试'
                    })
                })
                // .finally(() => {
                // })
            },
            copyNo(type){
                let content = '';
                let msg = '';
                if(type === 'orderNo'){
                    content =this.orderNo;
                    msg = '订单号';
                }else{
                    content =this.orderPayNo;
                    msg = '订单支付号'
                }

                navigator.clipboard.writeText(content).then(
                    () => {
                        this.$message({
                            type: 'success',
                            message: msg+'已成功复制到粘贴板,'
                        })
                    }).
                // eslint-disable-next-line no-unused-vars
                catch((err) => {
                    this.$message({
                        type: 'error',
                        message: '复制失败,请手动复制'
                    })
                });
            },
            querySearch(queryString, cb) {
                var restaurants = this.restaurants;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                console.log('results =>',results)
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                this.formData.name = item.key.name;
                this.formData.phone = item.key.phone;
                this.formData.email = item.key.email;
            },
        }
    };
</script>

<style scoped>
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        /*height: 100vh;*/
        align-items: flex-start;
    }

    .tabs {
        width: 90%;
        max-width: 500px; /* 设置最大宽度以防止内容过宽 */
    }

    .form {
        padding: 10px 20px;
        background-color: #f5f5f5;
        border-radius: 8px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    label {
        display: block;
        font-weight: bold;
        text-align: left;
        margin-bottom: 5px;
    }

    .el-input {
        width: 100%;
        margin-top: 3px;
    }

    .submit-btn {
        width: auto;
    }

    .payment-methods {
        display: flex;
        margin-top: 15px;
    }
    .payment-methods{
        margin-right: 20px;
    }

    .payment-method-label {
        font-size: 16px;
    }

    .copy-icon {
        padding-left: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .noDiv{
        display: flex;
        align-items: center;
    }

    .el-divider{
       margin: 10px 0;
    }
</style>
