import axios from 'axios'

export const uranusDomain = 'offline.metaso.cn';
export const uranusBaseUrl = 'https://' + uranusDomain;

axios.defaults.baseURL = uranusDomain;

const defaultHeaders = {
    Accept: 'application/json, text/plain, */*; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache'
}
Object.assign(axios.defaults.headers.common, Object.assign(defaultHeaders));

export const createOrder = (params) => {
    return axios.post(`${uranusBaseUrl}/admin/offline-order`, params);
}

export const searchOrder = (order_no) => {
    return axios.get(`${uranusBaseUrl}/admin/offline-order/${order_no}/status`);
}